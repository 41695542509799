import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import TablePaginationActions from "../../presentational/table-pagination-actions";
import TablePagination from "@material-ui/core/TablePagination";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import { history } from "../../../shared/configure-store";
import MenuItem from "@material-ui/core/MenuItem";
import { toast } from "react-toastify";
import moment from "moment";
import DateFormat from "../../../libraries/data-format-helper";
import Tooltip from "@material-ui/core/Tooltip";
import ErrorIcon from "@material-ui/icons/Error";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

export default class SalesOrderedLists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      rows: [],
      sendPageNumber: "",
      sendPerPage: "",
      perPage: 50,
      currentPage: 0,
      currentPageTable: 0,
      totalData: 0,
      showDetail: false,
      search: "",
      purchaseData: [],
      tollTagIds: [],
      deliveryProvider: [],
      tollTagErrors: [], // New array to store Toll Tag ID errors
      trackIdError: "", // New string to store Tracking ID error
      trackId: "",
      deliveryDays: 1,
      transponderShippedId: "",
      deliveryProviderId: "",
      selectedDeliveryProviderName: "",
      sortBy: "createdAt",
      sortOrder: "desc",
      start_date: null,
      end_date: null,
      dateError: false,
    };
    this.getPurchaseOrdered();
    this.handlePurchaseOrdered = this.handlePurchaseOrdered.bind(this);
    this.handleShippedOrder = this.handleShippedOrder.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getPurchaseOrdered = this.getPurchaseOrdered.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleTollTagChange = this.handleTollTagChange.bind(this);
  }

  saveToSessionStorage = () => {
    const storageThreshold = 3000; // Set to 3MB for caution; adjust as needed
    const {
      trackId,
      deliveryProviderId,
      tollTagIds,
      deliveryDays,
      purchaseData,
    } = this.state;
    const oId = purchaseData.transponder_order_id;

    if (JSON.stringify(sessionStorage).length >= storageThreshold) {
      console.warn(
        `Session storage nearing limit (${
          storageThreshold / 1000
        }KB). Clearing old data to free space.`
      );
      Object.keys(sessionStorage).forEach((key) => {
        if (
          key.startsWith("trackId_") ||
          key.startsWith("deliveryProviderId_") ||
          key.startsWith("tollTagIds_") ||
          key.startsWith("deliveryDays_")
        ) {
          sessionStorage.removeItem(key);
        }
      });
    }

    // Save the new data after clearing
    sessionStorage.setItem(`trackId_${oId}`, trackId);
    sessionStorage.setItem(`deliveryProviderId_${oId}`, deliveryProviderId);
    sessionStorage.setItem(`tollTagIds_${oId}`, JSON.stringify(tollTagIds));
    sessionStorage.setItem(`deliveryDays_${oId}`, deliveryDays.toString());
  };

  // Refined handleChange method
  handleChange = (e, prop) => {
    let value = e.target.value.replace(/\s+/g, ""); // Remove spaces

    // Check if the field being changed is the tracking ID
    if (prop === "trackId") {
      const { deliveryProviderId } = this.state;

      // Validate tracking ID and set error state immediately
      const trackIdError = this.validateTrackingId(value, deliveryProviderId);
      this.setState({ trackId: value, trackIdError }, () => {
        // Save to session storage if no validation error
        if (!this.state.trackIdError) {
          this.saveToSessionStorage();
        }
      });
    } else {
      // Handle other properties (e.g., deliveryDays)
      if (prop === "deliveryDays") {
        value = value ? parseInt(value, 10) : ""; // Set to empty string if no input
      }

      this.setState({ [prop]: value }, () => {
        this.saveToSessionStorage();
      });
    }
  };

  // Validate tracking ID based on the selected delivery provider
  validateTrackingId = (value, deliveryProviderId) => {
    let errorMessage = "";

    // Check if delivery provider is selected
    if (!deliveryProviderId) {
      return "Please select a delivery provider before entering the tracking ID.";
    }

    console.log(
      "validateTrackingId -> deliveryProviderId = ",
      deliveryProviderId
    );

    if (deliveryProviderId === "2" && !/^1Z[0-9A-Z]{16}$/.test(value)) {
      errorMessage =
        "UPS tracking numbers must start with '1Z' and be 18 characters long.";
    } else if (
      deliveryProviderId === "3" &&
      !/^(94|93|92|95|91|94|93)\d{20,22}$/.test(value)
    ) {
      errorMessage =
        "USPS tracking numbers must start with '94', '93', '92', or '95' and be 20-22 digits long.";
    } else if (deliveryProviderId === "1" && !/^\d{12}$/.test(value)) {
      errorMessage = "FedEx tracking numbers must be 12 digits long.";
    }

    return errorMessage;
  };

  handleTollTagChange(index, value) {
    const updatedTollTagIds = [...this.state.tollTagIds];
    const updatedTollTagErrors = [...this.state.tollTagErrors];

    // Remove spaces
    const trimmedValue = value.replace(/\s+/g, "");

    // Validate length and prefix
    if (trimmedValue.length === 14 && trimmedValue.startsWith("117")) {
      updatedTollTagErrors[index] = ""; // Clear error if valid
    } else {
      updatedTollTagErrors[index] =
        "Toll Tag ID must be 14 characters and start with '117'";
    }

    // Update TollTagIds and TollTagErrors in state
    updatedTollTagIds[index] = trimmedValue;
    this.setState({
      tollTagIds: updatedTollTagIds,
      tollTagErrors: updatedTollTagErrors,
    });
  }

  handleShippedOrder = () => {
    const {
      tollTagIds,
      selectedDeliveryProviderName,
      deliveryProviderId,
      trackId,
      purchaseData,
      deliveryDays,
    } = this.state;
    console.log(
      "Selected Delivery Provider Name in POST call:",
      selectedDeliveryProviderName
    );
    console.log("Selected Delivery Provider ID POST call:", deliveryProviderId);
    // Construct the request payload
    const payload = {
      ordered: {
        transponders: tollTagIds,
        userId: purchaseData.user_id,
        deliveryProvider: selectedDeliveryProviderName,
        deliveryProviderId: String(deliveryProviderId),
        orderId: purchaseData.transponder_order_id,
        trackingId: trackId,
        deliveryDays: deliveryDays,
      },
    };

    // Define the API endpoint
    const url =
      "https://prod.api.neoride.com/api/parking/v1/admin/transponder-order";
    const token = localStorage.getItem("access_token");

    // Perform the POST request
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Assuming token is stored in local storage
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          toast.success("Order shipped successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          // Clear session storage once shipped
          const oId = purchaseData.transponder_order_id;
          sessionStorage.removeItem(`trackId_${oId}`);
          sessionStorage.removeItem(`deliveryProviderId_${oId}`);
          sessionStorage.removeItem(`tollTagIds_${oId}`);
          sessionStorage.removeItem(`deliveryDays_${oId}`);
          // Close the dialog by updating showDetail
          this.setState({ showDetail: false });
          // Refresh the list by calling getPurchaseOrdered
          this.getPurchaseOrdered();
        } else {
          toast.error("Failed to ship order.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((error) => {
        console.error("Error in shipping order:", error);
        toast.error("Error in shipping order", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  getPurchaseOrdered() {
    const {
      perPage,
      currentPage,
      search,
      sortBy,
      sortOrder,
      start_date,
      end_date,
    } = this.state;

    const token = localStorage.getItem("access_token");

    // Construct the API URL
    let url = `https://prod.api.neoride.com/api/parking/v1/admin/transponder-order?limit=${perPage}&page=${
      currentPage + 1
    }&sortBy=${sortBy}&sortOrder=${sortOrder}&search=${search}`;

    // Append start and end date if provided
    if (start_date) {
      url += `&start_date=${moment(start_date).format("YYYY-MM-DD")}`;
    }
    if (end_date) {
      url += `&end_date=${moment(end_date).format("YYYY-MM-DD")}`;
    }

    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((resp) => {
        const data = resp.data || {}; // Adjust this based on the actual API response structure
        // Update state with fetched data
        this.setState({
          rows: data.orders || [],
          totalData: data.total || 0,
          loading: false,
        });
      })
      .catch((err) => {
        console.error("Error fetching transponder orders:", err);

        // Set error state in case of fetch failure
        this.setState({
          rows: [],
          totalData: 0,
          loading: false,
        });
      });
  }

  handleClose() {
    this.setState({ showDetail: false });
  }

  handlePurchaseOrdered(purchaseId) {
    const token = localStorage.getItem("access_token");
    let url =
      "https://prod.api.neoride.com/api/parking/v1/admin/transponder-order/" +
      purchaseId;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((resp) => {
        const data = resp.data || {}; // Adjust this based on the actual API response structure
        // Update state with fetched data
        this.setState({
          showDetail: true,
          purchaseData: data.orderDetails[0] || {},
        });
      })
      .catch((err) => {
        console.error("Error fetching transponder orders:", err);
        // Set error state in case of fetch failure
        this.setState({
          showDetail: false,
          purchaseData: {},
          tollTagIds: [], // Reset tollTagIds on error
        });
      });
  }

  handleKeyPressSearch = (event) => {
    if (event.key === "Enter") {
      this.setState(
        {
          rows: [],
          currentPage: 0,
          currentPageTable: 0,
          loading: true,
        },
        () => {
          this.getPurchaseOrdered();
        }
      );
    }
  };

  handleDateChange(date) {
    this.setState({ start_date: date }, () => {
      this.getPurchaseOrdered(); // Fetch data with updated start_date
    });
  }

  handleEndDateChange(date) {
    if (this.state.start_date && moment(date).isBefore(this.state.start_date)) {
      this.setState({ dateError: true });
    } else {
      this.setState({ end_date: date, dateError: false }, () => {
        this.getPurchaseOrdered(); // Fetch data with updated end_date
      });
    }
  }

  // Handle sorting functionality
  handleSort = (field) => {
    if (field === "createdAt") {
      const { sortOrder } = this.state;
      let newSortOrder = sortOrder === "asc" ? "desc" : "asc"; // Toggle sorting order
      this.setState(
        { sortBy: field, sortOrder: newSortOrder, loading: true },
        () => {
          this.getPurchaseOrdered();
        }
      );
    }
  };

  renderSortArrow = (field) => {
    const { sortBy, sortOrder } = this.state;
    if (sortBy === field) {
      return sortOrder === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
    }
    return null; // No arrow if the field is not sorted
  };

  handleChangePage = (event, newPage) => {
    this.setState(
      {
        rows: [],
        currentPage: newPage,
        currentPageTable: newPage,
        loading: true,
      },
      () => {
        this.getPurchaseOrdered();
      }
    );
  };

  handleChangeRowsPerPage = (event) => {
    this.setState(
      {
        perPage: parseInt(event.target.value, 10), // Ensure perPage is an integer
        currentPage: 0,
        currentPageTable: 0,
        loading: true,
      },
      () => {
        this.getPurchaseOrdered();
      }
    );
  };

  handleDeliveryProviderChange = (e) => {
    if (this.state.loading) {
      console.warn("Data is still loading, please wait.");
      return;
    }

    const selectedProviderId = parseInt(e.target.value, 10); // Convert to integer here
    const selectedProvider = this.state.deliveryProvider.find(
      (provider) => provider.id === selectedProviderId
    );

    console.log("Selected Provider ID:", selectedProviderId);
    console.log("Selected Provider Obj:", this.state.deliveryProvider);
    console.log(
      "Selected Provider Name:",
      selectedProvider ? selectedProvider.vendor : ""
    ); // Update 'provider' to 'vendor' based on your object structure

    this.setState({
      deliveryProviderId: String(selectedProviderId), // Convert to string if necessary for state
      selectedDeliveryProviderName: selectedProvider
        ? selectedProvider.vendor
        : "", // Update based on 'vendor' key
    });
  };

  componentDidMount = async () => {
    const { purchaseData } = this.state;
    const oId = purchaseData.transponder_order_id;
    const trackId = sessionStorage.getItem(`trackId_${oId}`) || "";
    const deliveryProviderId =
      sessionStorage.getItem(`deliveryProviderId_${oId}`) || "";
    const tollTagIds =
      JSON.parse(sessionStorage.getItem(`tollTagIds_${oId}`)) || [];
    const deliveryDays =
      parseInt(sessionStorage.getItem(`deliveryDays_${oId}`), 10) || 1;

    this.setState({ trackId, deliveryProviderId, tollTagIds, deliveryDays });

    const token = localStorage.getItem("access_token");
    try {
      const response = await fetch(
        "https://prod.api.neoride.com/api/parking/v1/admin/shipping-vendors",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const resp = await response.json();

      if (resp.success && resp.data) {
        const { shippingVendors } = resp.data;
        this.setState(
          {
            deliveryProvider: shippingVendors || [],
            loading: false,
          },
          () => {
            console.log(
              "Delivery Providers set in state:",
              this.state.deliveryProvider
            );
          }
        );
      }
    } catch (error) {
      console.error("Error fetching delivery providers:", error);
      this.setState({ loading: false });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { purchaseData } = this.state;
    if (
      purchaseData.transponder_order_id &&
      purchaseData.transponder_order_id !==
        prevState.purchaseData.transponder_order_id
    ) {
      const oId = purchaseData.transponder_order_id;
      const trackId = sessionStorage.getItem(`trackId_${oId}`) || "";
      const deliveryProviderId =
        sessionStorage.getItem(`deliveryProviderId_${oId}`) || "";
      const tollTagIds =
        JSON.parse(sessionStorage.getItem(`tollTagIds_${oId}`)) || [];
      const deliveryDays =
        parseInt(sessionStorage.getItem(`deliveryDays_${oId}`), 10) || 1;
      this.setState({ trackId, deliveryProviderId, tollTagIds, deliveryDays });
    }
  }

  render() {
    if (this.state.loading) {
      return <p>Loading providers...</p>; // Show a loading message until data is fetched
    }
    const { tollTagErrors, trackIdError } = this.state;
    return (
      <div className="row">
        <div className="col-12 card">
          <div className="row align-items-center">
            <div className="col-md-4 search-bar">
              <TextField
                required
                type="text"
                id={"adornment-search-ordered"}
                name="search"
                placeholder="Search"
                onChange={(e) => this.handleChange(e, "search")}
                onKeyPress={this.handleKeyPressSearch}
                value={this.state.search}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="Search button"
                        onClick={this.handleClickSearch}
                      >
                        <i className="fas fa-search"> </i>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </div>
            <div className="col-md-2 filter">
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  variant="inline"
                  margin="normal"
                  id="start_date_ordered"
                  label="Start Date"
                  format="MM/DD/YYYY"
                  value={this.state.start_date}
                  onChange={this.handleDateChange} // Start date change handler
                  KeyboardButtonProps={{ "aria-label": "change start date" }}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div
              className="col-md-2 filter"
              style={{ display: "flex", alignItems: "center" }}
            >
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  variant="inline"
                  margin="normal"
                  id="end_date_ordered"
                  label="End Date"
                  format="MM/DD/YYYY"
                  value={this.state.end_date}
                  onChange={this.handleEndDateChange} // End date change handler
                  KeyboardButtonProps={{ "aria-label": "change end date" }}
                  style={{
                    borderColor: this.state.dateError ? "red" : "initial",
                    borderWidth: this.state.dateError ? "2px" : "1px",
                    borderStyle: this.state.dateError ? "solid" : "none",
                  }}
                />
              </MuiPickersUtilsProvider>

              {/* Display tooltip with error icon if there's an error */}
              {this.state.dateError && (
                <Tooltip
                  title="End date cannot be earlier than start date"
                  placement="right"
                >
                  <ErrorIcon style={{ color: "red", marginLeft: "5px" }} />
                </Tooltip>
              )}
            </div>
          </div>
          {this.state.showDetail ? (
            <PurchaseOrderedDetail
              showDetail={this.state.showDetail}
              handleClose={this.handleClose}
              handleChange={this.handleChange}
              handleShippedOrder={this.handleShippedOrder}
              purchaseData={this.state.purchaseData}
              trackId={this.state.trackId}
              deliveryDays={this.state.deliveryDays}
              deliveryProviderId={this.state.deliveryProviderId}
              selectedDeliveryProviderName={
                this.state.selectedDeliveryProviderName
              }
              tollTagErrors={tollTagErrors}
              trackIdError={trackIdError}
              getPurchaseOrdered={this.getPurchaseOrdered}
              handleTollTagChange={this.handleTollTagChange} // Pass as a pro
              tollTagIds={this.state.tollTagIds}
              handleDeliveryProviderChange={this.handleDeliveryProviderChange}
              deliveryProvider={this.state.deliveryProvider}
              saveToSessionStorage={this.saveToSessionStorage}
            />
          ) : (
            ""
          )}
          <div className="table-wrapper">
            <Table className="table-list mt-3" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Order Number</TableCell>
                  <TableCell>No. Of Tags</TableCell>
                  <TableCell>User ID</TableCell>
                  <TableCell>User Name</TableCell>
                  <TableCell>User Address</TableCell>
                  <TableCell onClick={() => this.handleSort("createdAt")}>
                    Request Date {this.renderSortArrow("createdAt")}
                  </TableCell>
                </TableRow>
              </TableHead>
              {this.state.loading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={8} align="center" className="py-5">
                      <CircularProgress color="primary" />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <PurchaseOrderedTable
                  rows={this.state.rows}
                  handlePurchaseOrdered={this.handlePurchaseOrdered}
                />
              )}
            </Table>
          </div>
          <div>
            <TablePagination
              rowsPerPageOptions={[50, 75, 100]}
              component="div"
              count={this.state.totalData}
              rowsPerPage={this.state.perPage}
              page={this.state.currentPageTable}
              backIconButtonProps={{
                "aria-label": "previous page",
              }}
              nextIconButtonProps={{
                "aria-label": "next page",
              }}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </div>
        </div>
      </div>
    );
  }
}

class PurchaseOrderedTable extends Component {
  render() {
    let handlePurchaseOrdered = this.props.handlePurchaseOrdered;
    return (
      <TableBody>
        {this.props.rows.map(function (item, i) {
          return (
            <TableRow
              key={i}
              onClick={() => handlePurchaseOrdered(item.transponder_order_id)}
            >
              <TableCell>{item.transponder_order_number}</TableCell>
              {/* <TableCell>{item.transponder_order_id}</TableCell> */}
              <TableCell>{item.transponder_order_no_of_tags}</TableCell>
              <TableCell>{item.user_id}</TableCell>
              <TableCell>{item.user_full_name}</TableCell>
              <TableCell>{item.user_full_address}</TableCell>
              <TableCell>
                {DateFormat.dateTime(item.transponder_order_date)}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  }
}

class PurchaseOrderedDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deliveryProvider: [],
      verifyAddress: false,
      isVerifyA: false,
      loadingVerify: false,
      loadingCancel: false,
      showConfirmationDialog: false, // State for confirmation dialog
    };
  }

  areFieldsFilled = () => {
    const { tollTagErrors, tollTagIds, deliveryDays } = this.props;
    return (
      tollTagIds.every((tag) => tag) &&
      deliveryDays &&
      tollTagErrors.every((error) => !error)
    );
  };

  parseAddress(fullAddress) {
    const regex = /^(.+?)\s*,\s*(.+?)\s*,\s*(.+?)\s*-\s*(\d{5})$/;
    const match = fullAddress.match(regex);

    if (match) {
      const [address, city, state, zipcode] = match;
      const address2 = "";
      return { address, address2, city, state, zipcode };
    } else {
      console.error("Invalid address format");
      return { address: "", address2: "", city: "", state: "", zipcode: "" };
    }
  }

  verifyAddress = () => {
    const fullAddress = this.props.purchaseData["user_full_address"];
    const { address, city } = this.parseAddress(fullAddress);
    this.setState({ loadingVerify: true });

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        address: {
          regionCode: "US",
          locality: city,
          addressLines: address,
        },
      }),
    };
    const key = "AIzaSyBmVpWVL3DDfJu2W9GfCz1dCCtJt1w4RdQ";
    fetch(
      `https://addressvalidation.googleapis.com/v1:validateAddress?key=${key}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        console.log("API Response:", response);
        this.setState({ loadingVerify: false, verifyAddress: true });
        if (response.result.verdict.validationGranularity === "PREMISE") {
          console.log("Address verified successfully");
          this.setState({ isVerifyA: true });
        } else {
          console.warn("Address verification failed");
          this.setState({ showConfirmationDialog: true });
        }
      })
      .catch((err) => {
        console.error("Error validating address:", err);
        this.setState({ loadingVerify: false, showConfirmationDialog: true });
      });
  };

  cancelTransponderOrder = () => {
    const userConfirmed = window.confirm(
      "Are you sure you want to cancel this order? This action cannot be undone."
    );
  
    if (!userConfirmed) {
      // If the user cancels the confirmation, do nothing
      return;
    }
  
    const { purchaseData } = this.props;
    const orderId = purchaseData.transponder_order_id;
  
    const url = `https://stg.api.neoride.com/api/parking/v1/admin/transponder-order/${orderId}`;
    const token = localStorage.getItem("access_token");
  
    this.setState({ loadingCancel: true }); // Show spinner while processing
  
    fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          console.log("Order canceled successfully");
          toast.success("Order deleted successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          sessionStorage.removeItem(`trackId_${orderId}`);
          sessionStorage.removeItem(`deliveryProviderId_${orderId}`);
          sessionStorage.removeItem(`tollTagIds_${orderId}`);
          sessionStorage.removeItem(`deliveryDays_${orderId}`);
          this.props.handleClose();
          this.props.getPurchaseOrdered();
        } else {
          console.error("Failed to cancel order");
          toast.error("Failed to delete order.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((error) => {
        console.error("Error deleting order:", error);
        toast.error("Error deleting order", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
      .finally(() => {
        this.setState({ loadingCancel: false }); // Hide spinner
      });
  };
  

  handleConfirmationDialog = (action) => {
    if (action === "yes") {
      console.log("User confirmed address is correct");
      this.setState({
        verifyAddress: true,
        isVerifyA: true,
        showConfirmationDialog: false,
      });
    } else if (action === "no") {
      console.log("User decided the address is incorrect");
      this.setState({
        verifyAddress: false, // Reset to show Verify Address button again
        isVerifyA: false,
        showConfirmationDialog: false,
      });
    }
  };
  

  render() {
    const { showConfirmationDialog, isVerifyA, verifyAddress } = this.state;
    const { tollTagErrors, trackIdError, handleClose, tollTagIds } = this.props;

    return (
      <Dialog
        maxWidth={"md"}
        open={this.props.showDetail}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className="dialog-custom"
      >
        <DialogTitle id="form-dialog-title">
          <div className="row align-items-center">
            <div className="col-md-6">
              <span style={{ fontWeight: "bold" }}>Order</span>
              <span style={{ fontWeight: "bold" }}>
                #{this.props.purchaseData.transponder_order_number}
              </span>
              <span style={{ fontSize: "0.65em", marginLeft: "5px" }}>
                ({this.props.purchaseData.transponder_order_id})
              </span>
            </div>
            <div className="col-md-6">
              <p className="mb-0">
                <label className="title d-inline">Status</label>
                <i className="status text-capitalize"> Ordered</i>
              </p>
            </div>
          </div>
          <button onClick={handleClose} className="btn-close">
            <i className="fas fa-times"></i>
          </button>
        </DialogTitle>
        <DialogContent>
          <div>
            <div className="row">
              <div className="col-md-6">
                <label className="title">Account number</label>
                <p className="pl-2 pl-lg-3">
                  {this.props.purchaseData["user_id"]}
                </p>
                <button
                  onClick={() =>
                    history.push(
                      "/accounts/detail/" + this.props.purchaseData["user_id"]
                    )
                  }
                  className="btn btn-blue-trans mb-3"
                >
                  Go to Account
                </button>

                <label className="title">Contact and Shipping</label>
                <p className="pl-2 pl-lg-3">
                  Name - {this.props.purchaseData["user_full_name"]}
                  <br />
                  Phone - {this.props.purchaseData["user_phone"]}
                  <br />
                  Email - {this.props.purchaseData["user_email"]}
                  <br />
                  Address - {this.props.purchaseData["user_full_address"]}
                </p>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6">
                    <label className="title">Request Date</label>
                    <p className="pl-2 pl-lg-3">
                      {DateFormat.dateTime(
                        moment(
                          this.props.purchaseData["transponder_order_date"]
                        )
                      )}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <label className="title">Toll Tag Fee</label>
                    <p className="pl-2 pl-lg-3">
                      $
                      {parseFloat(
                        this.props.purchaseData.toll_tag_fee || 0
                      ).toFixed(2)}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <TextField
                      select
                      id="deliveryProviderId"
                      name="deliveryProviderId"
                      label="Delivery Provider"
                      onChange={this.props.handleDeliveryProviderChange}
                      value={this.props.deliveryProviderId}
                      fullWidth
                    >
                      {this.props.deliveryProvider &&
                        this.props.deliveryProvider.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.vendor}
                          </MenuItem>
                        ))}
                    </TextField>
                  </div>
                  <div className="col-md-6">
                    <Tooltip
                      title={
                        <>
                          <div>
                            <strong>UPS:</strong> Must start with "1Z" and be 18
                            characters.
                          </div>
                          <div>
                            <strong>USPS:</strong> Must start with "94", "93",
                            "92", or "95" and be 20-22 digits.
                          </div>
                          <div>
                            <strong>FedEx:</strong> Must be exactly 12 digits.
                          </div>
                        </>
                      }
                      placement="right"
                    >
                      <TextField
                        type="text"
                        id="trackId"
                        name="trackId"
                        label="Tracking Number"
                        onChange={(e) => this.props.handleChange(e, "trackId")}
                        minLength={4}
                        maxLength={50}
                        value={this.props.trackId}
                        error={!!this.props.trackIdError}
                        helperText={this.props.trackIdError}
                        fullWidth
                      />
                    </Tooltip>
                  </div>

                  <div className="col-md-6">
                    {[
                      ...Array(
                        this.props.purchaseData.transponder_order_no_of_tags ||
                          0
                      ),
                    ].map((_, index) => (
                      <TextField
                        required
                        key={index}
                        type="text"
                        id={`transponderShippedId_${index}`}
                        name={`transponderShippedId_${index}`}
                        label={`Toll Tag Id ${index + 1}`}
                        onChange={(e) =>
                          this.props.handleTollTagChange(index, e.target.value)
                        }
                        minLength={4}
                        maxLength={50}
                        value={this.props.tollTagIds[index] || ""}
                        error={!!tollTagErrors[index]}
                        helperText={tollTagErrors[index] || ""}
                        fullWidth
                      />
                    ))}
                  </div>
                  <div className="col-md-6">
                    <TextField
                      required
                      type="number"
                      id="deliveryDays"
                      name="deliveryDays"
                      label="Delivery Days"
                      onChange={(e) =>
                        this.props.handleChange(e, "deliveryDays")
                      }
                      inputProps={{ min: 1 }}
                      value={this.props.deliveryDays}
                      fullWidth
                    />
                  </div>
                  <div className="col-12">
                    <label className="title"></label>
                    <p className="pl-2 pl-lg-3"></p>
                  </div>

                  {verifyAddress ? (
                    isVerifyA ? (
                      <div className="col text-right">
                        <Tooltip
                          title="Sends Transponder. This button is enabled once all fields are completed."
                          placement="down"
                        >
                          <button
                            className="btn btn-blue-trans"
                            onClick={() =>
                              this.props.handleShippedOrder(
                                this.state.deliveryProviderDefaultId
                              )
                            }
                            disabled={
                              this.props.loadingButton || !this.areFieldsFilled()
                            }
                          >
                            Ship Transponder
                            {this.props.loadingButton && (
                              <i className="fa fa-spinner fa-spin"> </i>
                            )}
                          </button>
                        </Tooltip>
                      </div>
                    ) : (
                      <div className="col text-right">
                        <Tooltip
                        title="Your progress for this order is automatically saved in session storage to prevent data loss if the page is refreshed. You can continue updating the details as needed."
                        placement="left"
                      >
                        <IconButton
                          aria-label="info"
                          style={{ marginRight: "8px" }}
                        >
                          <i className="fas fa-info-circle" />
                        </IconButton>
                      </Tooltip>
                        <Tooltip title="Verify the entered address with Google’s Address Validation API." placement="down">
                          <button
                            className="btn btn-blue-trans"
                            onClick={() => this.setState({ showConfirmationDialog: true })}
                          >
                            Verify Address
                          </button>
                        </Tooltip>
                        <button
                          className="btn btn-danger mx-2"
                          onClick={this.cancelTransponderOrder}
                        >
                          Cancel Order
                          {this.state.loadingCancel && (
                            <i className="fa fa-spinner fa-spin" />
                          )}
                        </button>
                      </div>
                    )
                  ) : (
                    
                    <div className="col text-right">
                      <Tooltip
                        title="Your progress for this order is automatically saved in session storage to prevent data loss if the page is refreshed. You can continue updating the details as needed."
                        placement="left"
                      >
                        <IconButton
                          aria-label="info"
                          style={{ marginRight: "8px" }}
                        >
                          <i className="fas fa-info-circle" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title="Verify the entered address with Google’s Address Validation API."
                        placement="down"
                      >
                        <button
                          className="btn btn-blue-trans"
                          onClick={this.verifyAddress}
                          disabled={!this.areFieldsFilled()}
                        >
                          Verify Address
                          {this.state.loadingVerify && (
                            <i className="fa fa-spinner fa-spin" />
                          )}
                        </button>
                      </Tooltip>
                      <button
                        className="btn btn-danger mx-2"
                        onClick={this.cancelTransponderOrder}
                      >
                        Cancel Order
                        {this.state.loadingCancel && (
                          <i className="fa fa-spinner fa-spin" />
                        )}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Confirmation Dialog */}
          {showConfirmationDialog && (
            <Dialog
              open={showConfirmationDialog}
              onClose={() => this.setState({ showConfirmationDialog: false })}
            >
              <DialogTitle>Are you sure this address is correct?</DialogTitle>
              <DialogContent>
                <div className="text-center">
                  <button
                    className="btn btn-success mx-2"
                    onClick={() => this.handleConfirmationDialog("yes")}
                  >
                    Yes
                  </button>
                  <button
                    className="btn btn-danger mx-2"
                    onClick={() => this.handleConfirmationDialog("no")}
                  >
                    No
                  </button>
                </div>
              </DialogContent>
            </Dialog>
          )}
        </DialogContent>
      </Dialog>
    );
  }
}
